/* You can add global styles to this file, and also import other style files */

body {
  background-color: #2e2e2e;
}
.text-poppy-red {
  color: #ef5350 !important;
}
.btn-poppy-red {
  background-color: #ef5350 !important;
}
.bg-poppy-red {
  background-color: #ef5350 !important;
}
